import React, { Component } from "react";
import { ReactComponent as Theclock } from "./railclock.svg";
import "./clock.css";
class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let intervalId = setInterval(this.clock.bind(this), 1000);
    this.setState({ intervalId: intervalId });
    this.Calendar();
    let intervalCalendar = setInterval(this.Calendar.bind(this), 1000 * 60);
    this.setState({ intervalCalendar: intervalCalendar });
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    clearInterval(this.state.intervalCalendar);
  }
  clock() {
    let tijd = new Date();
    let uur = tijd.getHours() * 30;
    let minuut = tijd.getMinutes() * 6;
    let sec = Math.round(tijd.getSeconds());
    //let mili = Math.round(tijd.getMilliseconds());
    //let now = sec + "." + mili;
    let current = sec * 6;
    current = Math.round(current * 100) / 100;
    document.getElementsByTagName("use")[15].style.transform =
      "rotate(" + uur + "deg)";
    document.getElementsByTagName("use")[16].style.transform =
      "rotate(" + minuut + "deg)";
    document.getElementsByTagName("use")[17].style.transform =
      "rotate(" + current + "deg)";
  }

  Calendar() {
    var time = new Date();
    var locale = "nl-NL";

    var DD = time.getDate();
    var DDD = time.toLocaleString(locale, { weekday: "long" }).toUpperCase();
    var MMM = time.toLocaleString(locale, { month: "long" }).toUpperCase();

    this.setState({ day: DD });
    this.setState({ weekday: DDD });
    this.setState({ month: MMM });
  }

  render() {
    return (
      <div>
        <Theclock />
        <div className="date">
          <div className="weekday">{this.state.weekday}</div>
          <div className="day">
            <span>{this.state.day}</span>
            <span>{this.state.month}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Clock;
