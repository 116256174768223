import React, { Component } from "react";
import _ from "lodash";
import CircularProgressbar from "react-circular-progressbar";

import Page from "./page";
//import Clock from "./components/clock/clock";
//import Calendar from "./components/calendar/calendar";
//import Weather from "./components/weather/weather";
import "./Routes.scss";
import "react-circular-progressbar/dist/styles.css";
import "./Ticker.scss";
import { api } from "../../config";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: [],
      lines: [],
      dates: [],
      page: [0],
      timer: 0,
      ticker: 10000,
      itteration: 0,
      jump: 1000,
    };
    this.force = this.force.bind(this);
  }

  componentDidMount() {
    this.loadData();

    let dataload = setInterval(this.loadData.bind(this), 1000 * 60 * 5);
    this.setState({ dataload: dataload });

    document.addEventListener("keydown", this.force, false);
  }
  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
    clearInterval(this.state.dataload);
    document.removeEventListener("keydown", this.force, false);
  }

  ticker() {
    let iteration = this.state.itteration;
    let tick = this.state.ticker - this.state.jump;
    iteration = iteration + this.state.jump;
    this.setState({ timer: (iteration / tick) * 100, itteration: iteration });
  }

  pageswitch(backwards) {
    if (
      (this.state.page[0] === this.state.page.length ||
        this.state.lines.length === 0) &&
      !backwards
    ) {
      this.props.next(true);
    } else if (backwards && this.state.page[0] === 1) {
      this.props.next(false);
    }
    if (backwards) {
      let ara = this.state.show;
      ara.push(ara.splice(0, 1)[0]);
      this.setState({ show: ara });
      let date = this.state.dates;
      date.unshift(date.pop());
      this.setState({ dates: date });
      let pages = this.state.page;
      pages.unshift(pages.pop());
      this.setState({ page: pages });
      this.setState({ itteration: 0, timer: 0 });
    } else {
      let ara = this.state.show;
      ara.unshift(ara.pop());
      this.setState({ show: ara });
      let date = this.state.dates;
      date.push(date.splice(0, 1)[0]);
      this.setState({ dates: date });
      let pages = this.state.page;
      pages.push(pages.splice(0, 1)[0]);
      this.setState({ page: pages });
      this.setState({ itteration: 0, timer: 0 });
    }
  }

  loadData() {
    clearInterval(this.state.intervalId);
    clearInterval(this.state.ticksave);
    fetch(api + "/plugins/vitamientje_module/getCastRoutes")
      .then((res) => res.json())
      .then((response) => {
        if (response.length > 0) {
          let today = this.slice(response);
          this.setState({ show: today.val });
          this.setState({ lines: today.result });
          this.setState({ dates: today.dates });
          this.setState({ page: today.page });
          let ticksave = setInterval(this.ticker.bind(this), this.state.jump);
          let intervalId = setInterval(
            this.pageswitch.bind(this),
            this.state.ticker
          );
          // store intervalId in the state so it can be accessed later:
          this.setState({ intervalId: intervalId, ticksave: ticksave });
        } else {
          this.props.next(true);
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  force(e) {
    if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      clearInterval(this.state.intervalId);
      clearInterval(this.state.ticksave);
      this.setState({ itteration: 0, timer: 0 });
      if (e.key === "ArrowLeft") {
        this.pageswitch(true);
      } else {
        this.pageswitch(false);
      }
      let ticksave = setInterval(this.ticker.bind(this), this.state.jump);
      let intervalId = setInterval(
        this.pageswitch.bind(this),
        this.state.ticker
      );
      // store intervalId in the state so it can be accessed later:
      this.setState({ intervalId: intervalId, ticksave: ticksave });
    }
  }

  slice(arr) {
    let maxlines = 8;
    let dates = _.toArray(_.groupBy(arr, "user_fullname"));
    let show = [];
    let lines = [];
    let date = [];
    let page = [];
    dates.forEach((date) => {
      let slice = (source, index) => source.slice(index, index + maxlines);
      let length = date.length;
      let index = 0;
      let result = [];
      while (index < length) {
        let temp = slice(date, index);
        result.push(temp);
        index += maxlines;
      }
      lines.push(result);
    });
    let currentpage = 1;
    lines.forEach((line, i) => {
      line.forEach((e, x) => {
        var parts = e[0].order_delivery_moment.split("-");
        var time = new Date(
          parseInt(parts[2], 10),
          parseInt(parts[1], 10) - 1,
          parseInt(parts[0], 10)
        );
        var locale = "nl-NL";

        var DD = time.getDate();
        var DDD = time.toLocaleString(locale, { weekday: "long" });
        var MMM = time.toLocaleString(locale, { month: "long" });
        if (i === 0 && x === 0) {
          show.push(true);
        } else {
          show.push(false);
        }
        page.push(currentpage++);
        date.push(
          DDD.charAt(0).toUpperCase() +
            DDD.slice(1) +
            " " +
            DD +
            " " +
            MMM.charAt(0).toUpperCase() +
            MMM.slice(1) +
            " " +
            e[0].user_fullname
        );
      });
    });
    let data = { result: lines, val: show, dates: date, page: page };
    return data;
  }

  render() {
    let iteration = -1;
    return (
      <div key="route" className={"routes " + this.props.color}>
        <div className="pages">
          <CircularProgressbar
            key={this.state.page}
            className="fast-animation"
            percentage={this.state.timer}
            text={this.state.page[0] + "/" + this.state.page.length}
          />
          <div className="pagecounter" />
        </div>
        <div className="dateswitch">
          <div className="rounded">{this.state.dates[0]}</div>
        </div>
        <div className="holder">
          <div className="header">
            {/*
            <div className="blocks show firstblock">
              <span>Route</span>
            </div>
            */}
            <div className="blocks show secondblock">
              <span>Levertijd</span>
            </div>
            <div className="blocks show thirdblock">
              <span>Order</span>
            </div>
            <div className="blocks show fourthblock">
              <span>Klant</span>
            </div>
            <div className="blocks show fifthblock">
              <span>Plaats</span>
            </div>
            <div className="blocks show sixthblock">
              <span>Postcode</span>
            </div>
            <div className="blocks show tenthblock">
              <span>Laadtijd</span>
            </div>
            <div className="blocks show eighthblock">
              <span>Vertrektijd</span>
            </div>
            <div className="blocks show seventhblock">
              <span>Aflevertijd</span>
            </div>
            <div className="blocks show ninethblock">
              <span />
            </div>
          </div>
          {this.state.lines.map((dates, i) => {
            return dates.map((pagedata, i) => {
              iteration++;
              return (
                <Page
                  key={dates[i]}
                  show={this.state.show[iteration]}
                  data={pagedata}
                />
              );
            });
          })}
        </div>
      </div>
    );
  }
}

export default Routes;
