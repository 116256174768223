import React, { Component, Fragment } from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import _ from "lodash";

import Clock from "../clock/clock";
import { ReactComponent as Office } from "./office.svg";

import "./gps.scss";
import { api } from "../../config";

const AnyReactComponent = ({ text, img }) => (
  <div className="marker">
    {img > "" ? <Office className="office" /> : false}
    {text}
  </div>
);

class GPS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: { width: 1920, height: 980 },
      markers: [],
      bounds: {
        ne: {
          lat: 0,
          lng: 0,
        },
        sw: {
          lat: 0,
          lng: 0,
        },
      },
    };
  }

  componentDidMount() {
    this.loadData();
    let dataload = setInterval(this.loadData.bind(this), 1000 * 60 * 5.1);
    this.setState({ dataload: dataload });
  }
  componentWillUnmount() {
    clearInterval(this.state.dataload);
  }

  loadData() {
    fetch(api + "/plugins/vitamientje_module/getCastGPS")
      .then((res) => res.json())
      .then((response) => {
        if (response.length > 0) {
          let marking = [];
          response.map((mark) => {
            marking.push({
              text: mark.user_fullname,
              lat: mark.gps_log_lat,
              lng: mark.gps_log_lon,
            });
          });
          marking.push({
            img: "https://www.svgrepo.com/show/10927/office-buildings.svg",
            text: "Kantoor",
            lat: "50.804600",
            lng: "5.720030",
          });
          this.setState(
            {
              markers: marking,
            },
            () => {
              this.FoV();
            }
          );
        } else {
          let marking = [];
          marking.push({
            img: "https://www.svgrepo.com/show/10927/office-buildings.svg",
            text: "Kantoor",
            lat: "50.804600",
            lng: "5.720030",
          });
          this.setState(
            {
              markers: marking,
            },
            () => {
              this.FoV();
            }
          );
          //this.props.next(true);
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  FoV() {
    let nwlat = _.maxBy(this.state.markers, function (o) {
      return o.lat;
    });
    let nwlng = _.maxBy(this.state.markers, function (o) {
      return o.lng;
    });
    let selat = _.minBy(this.state.markers, function (o) {
      return o.lat;
    });
    let selng = _.minBy(this.state.markers, function (o) {
      return o.lng;
    });
    let bound = {
      ne: {
        lat: nwlat.lat,
        lng: nwlng.lng,
      },
      sw: {
        lat: selat.lat,
        lng: selng.lng,
      },
    };
    if (this.state.markers && this.state.markers.length > 1) {
      this.setState({ bounds: bound }, () => {
        let { center, zoom } = fitBounds(this.state.bounds, this.state.size);
        if (zoom > 10) {
          zoom = 10;
        }
        if (zoom < 6) {
          zoom = 6;
        }
        this.setState(
          { ...this.state.bounds, bounds: { center, zoom } },
          () => {}
        );
      });
    } else {
      this.setState({ bounds: bound }, () => {
        this.setState(
          {
            ...this.state.bounds,
            bounds: {
              ...this.state.bounds,
              center: {
                lat: 50.8046,
                lng: 5.72003,
              },
              zoom: 12,
            },
          },
          () => {}
        );
      });
    }
  }
  render() {
    return (
      <div key="gps" className={"app " + this.props.color}>
        <div
          style={{
            height: this.state.size.height + "px",
            width: this.state.size.width + "px",
          }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCvuq_EYSsLuOr3HGKl6q2vWfRX_0p4T5c",
            }}
            center={this.state.bounds.center}
            zoom={this.state.bounds.zoom}
            onChange={(e) => (
              console.log("zoom", this.state.bounds.zoom), this.FoV()
            )}
          >
            {this.state.markers.map((mark, i) => {
              return (
                <AnyReactComponent
                  lat={mark.lat}
                  lng={mark.lng}
                  text={mark.text}
                  img={mark.img}
                  key={i}
                />
              );
            })}
          </GoogleMapReact>
        </div>
      </div>
    );
  }
}

export default GPS;
